export function getEnv() {
  let env = '';
  if (window.location.hostname.indexOf('localhost') !== -1) {
    env = 'qa';
  }

  if (
    (window.location.hostname.indexOf('localhost.wash') !== -1 &&
      window.location.href.indexOf('/pb/') !== -1) ||
    window.location.hostname.indexOf('pb-impact') !== -1 ||
    window.location.hostname.indexOf('impact.wash') !== -1 ||
    window.location.hostname.indexOf('subs-stage') !== -1 ||
    window.location.hostname.indexOf('vforum-stage.') !== -1
  ) {
    env = 'stage';
  } else if (
    (window.location.hostname.indexOf('localhost.wash') !== -1 &&
      window.location.href.indexOf('/pf/') !== -1) ||
    window.location.hostname.indexOf('localhost.wash') !== -1 ||
    window.location.hostname.indexOf('sandbox.wash') !== -1 ||
    window.location.hostname.indexOf('subs-stage') !== -1 ||
    window.location.hostname.indexOf('preview.now.washingtonpost.com') !== -1
  ) {
    env = 'fStage';
  } else if (
    (window.location.hostname.indexOf('localhost.digitalink') !== -1 &&
      window.location.href.indexOf('/pf/') !== -1) ||
    (window.location.hostname.indexOf('localhost.digitalink') !== -1 &&
      window.location.href.indexOf(':3000') !== -1) ||
    window.location.hostname.indexOf('www.digitalink.com') !== -1 ||
    (window.location.hostname.indexOf('dev.wash') !== -1 &&
      window.location.href.indexOf('/pf/') !== -1)
  ) {
    env = 'fQa';
  } else if (
    window.location.hostname.indexOf('digitalink.com') !== -1 ||
    window.location.hostname.indexOf('dev.wash') !== -1
  ) {
    env = 'qa';
  }

  return env;
}

export function getWorkingEnv(workingEnv = '') {
  if (
    window.location.pathname.indexOf(`/${workingEnv}`) !== -1 ||
    window.parent.location.pathname.indexOf(`/${workingEnv}`) !== -1 // iframe use case
  ) {
    // is creativegroup or licensing-syndication
    return true;
  }
  if (
    workingEnv === 'wp-intelligence' &&
    window.location.origin === 'https://demo.wpintelligence.com'
  ) {
    return true;
  }
  return false;
  // expand if else statements to include other environments as more teams adopt the short footer
}
