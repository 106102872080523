import { getWorkingEnv } from '../utils/getEnv.js';

/**
 * @fileoverview This file contains the "short footer design" template for the footer.
 * @type {string} type - The type of footer to be rendered, e.g. 'standard', 'ccpa', 'eac', 'do-not-sell'
 */

const needHelpUrl = getWorkingEnv('licensing-syndication')
  ? 'https://twplshelp.zendesk.com/hc/en-us'
  : 'https://helpcenter.washingtonpost.com/hc/en-us';

const needHelpLink = `<a part="link" class="dib-ns db gray-dark pa-xxs pl-md pr-md" href=${needHelpUrl} target="_blank">Need Help?</a>`;

const needHelp = `
  <li part="need-help footer-item" class="flex justify-center flex-1 order-3 mx-4" tabindex="0">
   ${needHelpLink}
  </li>`;

const yourPrivacyChoices = `
<li part="your-privacy-choices footer-item" id="privacy-choices" class="flex-1 order-3 dn mx-4" tabindex="0">
  <a part="link your-privacy-choices-link" class="db gray-dark pa-xxs flex justify-center items-self-start align-center" target="_blank" href="https://www.washingtonpost.com/my-post/privacy-settings?tid=ft_sell_info" rel="noopener noreferrer" data-cy="your privacy choices">
    Your Privacy Choices
    <img part="privacy-options-img" class="pl-5 " src="https://subscribe.washingtonpost.com/static/dns-icons/privacyoptions29x14.png" alt="California Consumer Privacy Act (CCPA) Opt-Out Icon">
  </a>
</li>
`;

// wp-intelligence is a instapapge. Privacy Footer will include Terms of Service link.
// getWorkingEnv checks for origin of the page and expects it to be 'https://demo.wpintelligence.com'
// and also argumented passed into the function and as additional guard
const ToS = getWorkingEnv('wp-intelligence')
  ? `
  <li part="ToS footer-item" class="flex justify-center flex-1 order-3 mx-4" tabindex="0">
    <a part="link" href="https://www.washingtonpost.com/terms-of-service/" target="_blank" class="dib-ns db gray-dark pa-xxs pl-md pr-md">Terms of Service</a>
  </li>`
  : '';

const eac = `
  ${needHelp}
  ${yourPrivacyChoices}
  ${ToS}
  <li part="eu-ad-consent footer-item" class="flex-1 order-3 mx-4" tabindex="0">
    <a part="link" class="dib-ns db gray-dark pa-xxs flex justify-center items-self-start" target="_blank" href="https://www.washingtonpost.com/my-post/privacy-settings-eac/" rel="noopener noreferrer">
      EU Advertising Consent
    </a>
  </li>
`;

const doNotSell = `
  ${needHelp}
  ${yourPrivacyChoices}
  ${ToS}
`;

const ccpa = `
${needHelp}
  <li part="ca-notice-collection footer-item" class="flex-1 order-3 mx-4" tabindex="0">
    <a part="link" class="dib-ns db gray-dark pa-xxs pl-md pr-md" target="_blank" href="https://www.washingtonpost.com/privacy-policy/2011/11/18/gIQASIiaiN_story.html#CALIFORNIA?tid=ft_ccpa_notice" rel="noopener noreferrer" data-cy="CA-notice-of-collection">
      CA Notice of Collection
    </a>
  </li>
  ${yourPrivacyChoices}
  ${ToS}
`;

const invisibleElement = `
  <span id="footer-present" data-template="wpdp-footer" style="display:none;width:0;height:0;" aria-hidden="true" tabindex="-1">
  </span>
`;

export const FooterShortTemplate = type => {
  const ccpaFooter = type === 'ccpa';
  const doNotSellFooter = type === 'donotsell';
  const eacFooter = type === 'eac';

  let additionalLinks = ``;

  if (eacFooter) {
    additionalLinks = eac;
  } else if (ccpaFooter) {
    additionalLinks = ccpa;
  } else if (doNotSellFooter) {
    additionalLinks = doNotSell;
  } else {
    additionalLinks = needHelp;
  }
  const template = `
    <footer part="footer-wpdp" id="${type}-footer" class="background-white w-100 font-xxxxs font-xxxs-ns">
      <ul part="list-links" class="${
        !ccpaFooter ? 'm-100 pt-xs pb-xs ' : ''
      } mw-1200 flex center justify-center h-100 pt-xs pb-xs list-unstyled ma-auto pa-0 flex-column flex-ns-row gray-dark">
        <li part="privacy-policy footer-item" class="${
          !ccpaFooter ? 'flex justify-center' : ''
        } flex-1 order-1-ns order-2 flex-start mx-4" tabindex="0">
          <a part="link" class="dib-ns db gray-dark pa-xxs pl-md pr-md" target="_blank" href="https://www.washingtonpost.com/privacy-policy/2011/11/18/gIQASIiaiN_story.html?utm_term=.98a6edd372fc" rel="noopener noreferrer" tabindex="0">
            Privacy Policy
          </a>
        </li>
        <li part="secure-encrypted footer-item" class="flex-1 justify-center order-2-ns order-1 mx-4" tabindex="0">
          <div class="flex justify-center align-center pt-xxs pb-xxs" part="secure-encrypted-container"->
            <svg part="padlock-svg" xmlns="http://www.w3.org/2000/svg" width="9" height="14.04" viewBox="0 0 18 28">
              <path fill="#6bab2e" d="M5 12h8V9c0-2.203-1.797-4-4-4S5 6.797 5 9v3zm13 1.5v9a1.5 1.5 0 01-1.5 1.5h-15A1.5 1.5 0 010 22.5v-9A1.5 1.5 0 011.5 12H2V9c0-3.844 3.156-7 7-7s7 3.156 7 7v3h.5a1.5 1.5 0 011.5 1.5z"></path>
            </svg>
            <span class="pl-xxs gray-dark" part="secure-and-encrypted">Secure &amp; Encrypted</span>
          </div>
        </li>
        ${additionalLinks}
      </ul>
      ${invisibleElement}
  </footer>

  `;
  return template;
};
